import { mapGetters, mapActions } from 'vuex'
import authMixin from '@/mixins/auth-mixin'
const DonationItem = () => import(/* webpackChunkName = "c-article-item" */'@/components/DonationItem')
const AddDonationForm = () => import(/* webpackChunkName = "c-add-form" */'@/components/AddDonationForm')

export default {
  name: 'Fellowship',
  mixins: [authMixin],
  components: {
    DonationItem,
    AddDonationForm
  },
  data () {
    return {
      visibleAddForm: false,
      editId: ''
    }
  },
  created () {
    this.getDonationAction({
      success: this.handleSuccess
    })
  },
  computed: {
    ...mapGetters('donation', ['donations']),
    ...mapGetters('user', ['token']),
    selectedEditDonation () {
      return this.donations.find(donation => donation.id === this.editId) || {}
    }
  },
  methods: {
    ...mapActions('donation', ['getDonationAction', 'deleteDonationAction']),
    handleSuccessDelete () {
      this.$swal({
        icon: 'success',
        title: 'Success Delete',
        showConfirmButton: false,
        timer: 1500
      })
    },
    handleDeleteDonation (id) {
      this.$swal({
        title: 'Apakah Anda Yakin?',
        text: 'Anda tidak akan dapat mengembalikan ini!',
        icon: 'warning',
        confirmButtonText: 'Yes, hapus!',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteDonationAction({
            success: this.handleSuccessDelete,
            data: {
              id: id,
              token: this.token
            }
          })
        }
      })
    },
    toggleAddForm (add = false) {
      if (add) this.editId = ''
      this.visibleAddForm = !this.visibleAddForm
    },
    handleEditDonation (id) {
      this.editId = id
      this.toggleAddForm()
    }
  }
}
