import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('user', ['roles'])
  },
  created () {
    this.userCheck(this.$options.name)
  },
  methods: {
    userCheck (page) {
      if (!this.roles) return
      if (!this.roles.includes(page.toUpperCase())) {
        this.$router.push('/dashboard')
      }
    }
  },
  watch: {
    roles () {
      this.userCheck(this.$options.name)
    }
  }
}
